#web-messenger-container {
  left: 20% !important;
  width: 60% !important;
  bottom: 0% !important;
  position: relative !important;
  max-width: 100% !important;
  min-height: calc(100vh - 150px) !important;
  max-height: 100% !important;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  #web-messenger-container {
    left: 0% !important;
    width: 100% !important;
  }
}
